import './styles.scss';

import * as yup from 'yup';

import { ErrorMessage, Formik } from 'formik';
import React, { useEffect, useState } from 'react';

import AuthStateProvider from '../components/authStateProvider';
import FormField from '../components/form/formField';
import Header from '../components/header/header';
import SEO from '../components/SEO';
import { isEmpty } from 'lodash';
import { navigate } from 'gatsby';
import { sendRecoverEmail } from '../actions/authActions';

const validationSchema = yup.object({
  email: yup.string().email().required('Email is required.'),
});

const ForgotPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ status: false, message: '' });

  const handleResetEmail = async ({ email }) => {
    setLoading(true);
    setMessage({ status: false, message: '' });
    const response = await sendRecoverEmail(email);
    const resp = await response.json();
    if (response.ok) {
      setMessage({
        status: true,
        message: "Please check your email for reset code, You'll be redirected shortly to Password reset page.",
      });
    } else {
      setMessage({ status: false, message: resp.message });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (message.status) {
      setTimeout(() => {
        navigate('/resetpassword');
      }, 3000);
    }
  }, [message]);

  return (
    <AuthStateProvider authRequired={false} className="main-container bg-grey h-100" {...props}>
      <SEO title="Forgot Password" />
      <Header hideMiddle={true} show={true} />
      <div className="h-100">
        <div className="bg-white forgot-inner-container">
          <div className="login-title dsp-flx flx-col flx-center">
            <h3 className="fnt-evolventa bold fnt-primary">Reset your Password</h3>
            <p className="fnt-evolventa desc fnt-r-16 text-align-center">
              A reset code will be sent to the email provided below, Please provide that code in the next page to reset
              your password.
            </p>
            <div className="mt-5 form-wrap">
              <Formik
                validationSchema={validationSchema}
                initialValues={{
                  email: '',
                }}
                onSubmit={handleResetEmail}
              >
                {({ values, errors, handleChange, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <FormField
                      required
                      value={values.email}
                      name="email"
                      label="Email"
                      onChange={handleChange}
                      containerClass="form-control-text"
                    />
                    <ErrorMessage name="email" />
                    <button
                      disabled={loading || message.status}
                      className="login-confirm bg-prime p-3 fnt-white dsp-flx flx-center mt-4"
                      role="button"
                    >
                      <p className="m-0 fnt-evolventa fnt-weight-600">Send Reset Code</p>
                    </button>
                  </form>
                )}
              </Formik>
              {!isEmpty(message.message) && (
                <p className={`fnt-poppins fnt-16 ${message.status ? 'fnt-green' : 'fnt-alert'}`}>{message.message}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </AuthStateProvider>
  );
};

export default ForgotPassword;
